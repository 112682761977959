<template>
  <div class="full-width">
    <app-bar-your-profile v-if="tab === 'profile'" />
    <app-bar-your-company v-if="tab === 'company'" />
    <app-bar-platform v-if="tab === 'platform'" />
    <app-bar-location v-if="tab === 'locations'" />
    <app-bar-tags v-if="tab === 'tags'" />
    <app-bar-groups v-if="tab === 'groups'" />
    <app-bar-resources v-if="tab === 'resources'" />
  </div>
</template>

<script>
import AppBarPlatform from "@/views/dashboard/pages/Settings/AppBar/AppBarPlatform.vue";
import AppBarLocation from "@/views/dashboard/pages/Settings/AppBar/AppBarLocation.vue";
import AppBarTags from "@/views/dashboard/pages/Settings/AppBar/AppBarTags.vue";
import AppBarGroups from "@/views/dashboard/pages/Settings/AppBar/AppBarGroups.vue";
import AppBarResources from "@/views/dashboard/pages/Settings/AppBar/AppBarResources.vue";
import AppBarYourProfile from "@/views/dashboard/pages/Settings/AppBar/AppBarYourProfile.vue";
import AppBarYourCompany from "@/views/dashboard/pages/Settings/AppBar/AppBarYourCompany.vue";

export default {
  components: {
    AppBarYourCompany,
    AppBarYourProfile,
    AppBarResources,
    AppBarGroups,
    AppBarTags,
    AppBarLocation,
    AppBarPlatform,
  },
  computed: {
    tab() {
      if (this.$route.params) {
        return this.$route.params.tab;
      }
      return null;
    },
  },
};
</script>
