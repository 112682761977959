<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex justify-start align-center">
        <new-app-bar-route
          :title="$trans('profile_account')"
          :subtitle="$trans('section_admin_panel_settings')"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-start justify-md-end align-center"
      >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewAppBarRoute from "@/views/dashboard/components/AppBar/AppBarComponents/NewAppBarRoute.vue";

export default {
  components: {
    NewAppBarRoute,
  },
};
</script>
